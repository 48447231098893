import React from "react"
import {
  Box,
  Text,
  Divider,
  Heading,
  PseudoBox,
  Image,
  SimpleGrid,
} from "@chakra-ui/core"
import { NavigateBefore } from "@emotion-icons/material/NavigateBefore"
import { NavigateNext } from "@emotion-icons/material/NavigateNext"
import { Slide, Zoom } from "react-awesome-reveal"
import { useStaticQuery, graphql } from "gatsby"
import { getPosts, getPostLink } from "../../utils/post"
import BlogLink from "../Blog/BlogLink"
import { PostInterface } from "../../types/post"
import SectionTitle from "../SectionTitle"

interface RecentNewsCardProps {
  post: PostInterface
}

const RecentNewsCard = (props: RecentNewsCardProps) => {
  const { post } = props

  return (
    <BlogLink to={getPostLink(post)}>
      <Box
        my={2}
        bg="white"
        boxShadow="0 6px 10px rgba(0,0,0,.08)"
        maxW="330px"
        mx="auto"
      >
        <Box position="relative">
          <Box overflow="hidden">
            <PseudoBox
              transition="all 0.3s"
              _hover={{ transform: "scale(1.1)" }}
            >
              <Image
                w="330px"
                h="200px"
                objectFit="cover"
                src={post.image.url}
                alt={post.image.alt}
              />
            </PseudoBox>
          </Box>
          <Text
            bg="#FFD400dd"
            mx="auto"
            w="60%"
            textAlign="center"
            fontWeight="bold"
            position="absolute"
            left="50%"
            bottom={0}
            fontSize="lg"
            transform="translate(-50%, 50%)"
          >
            {post.categories[0].name}
          </Text>
        </Box>

        <Text
          fontSize={["lg", null, "lg"]}
          fontWeight="bold"
          mt={6}
          px={3}
          py={3}
          textAlign="center"
          color="#707070"
        >
          {post.title}
        </Text>
      </Box>
    </BlogLink>
  )
}
const PreviousArrow = props => {
  const { onClick } = props

  return (
    <div onClick={onClick}>
      <Box
        border="1px solid #FFFFFF"
        position="absolute"
        right={["18%", "13%", "9%", "8%"]}
        top={["-30%", "-30%", "-40%"]}
      >
        <NavigateBefore size="30px" color="#fff" />
      </Box>
    </div>
  )
}

const NextArrow = props => {
  const { onClick } = props

  return (
    <div onClick={onClick}>
      <Box
        border="1px solid #FFFFFF"
        position="absolute"
        right="5%"
        top={["-30%", "-30%", "-40%"]}
      >
        <NavigateNext size="30px" color="#fff" />
      </Box>
    </div>
  )
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  className: "center",
  centerMode: true,
  centerPadding: "0px",
  nextArrow: <NextArrow />,
  prevArrow: <PreviousArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const RecentNewsSection = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicPost(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            url
            data {
              title {
                text
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      data {
                        name
                      }
                    }
                  }
                }
              }
              content {
                raw
              }
              date(formatString: "MMM D, YYYY")
              main_image {
                alt
                url
              }
              description {
                text
              }
            }
          }
        }
      }
    }
  `)

  const posts = getPosts(data)

  return (
    <Box
      bg="#f5f5f5"
      pb={20}
      px={[4, 6, 8]}
      pt={[4, 6, 8]}
      position="relative"
      zIndex={0}
    >
      <Box>
        <Box py={4} mb={24} textAlign="center">
          <SectionTitle title="சமீபத்திய செய்தி" />
          <Zoom duration={500} triggerOnce>
            <Text color="#707070" fontSize={["xl", "2xl"]} mb={3}>
              அரசாங்க தேர்வுகள் பற்றிய கூடுதல் செய்திகளை அறிய
            </Text>
          </Zoom>
        </Box>
        <SimpleGrid
          columns={[1, null, 2, 3]}
          w="100%"
          maxW={["300px", "1250px"]}
          mt={5}
          mx="auto"
          spacing={10}
        >
          {posts.map(post => (
            <Zoom duration={500} triggerOnce>
              <RecentNewsCard post={post} />
            </Zoom>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default RecentNewsSection
