import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import TestimonialSection from "../components/TestimonialSection"
// import ResultsSection from "../components/ResultsSection"
import PostCardSection from "../components/Home/PostCardSection"
import AchievementsSection from "../components/Home/AchievmentsSection"
import BannerWithSlider from "../components/Home/BannerWithSlider"
import Layout from "../components/Layout"
import { Box } from "@chakra-ui/core"
import RecentNewsSection from "../components/Home/RecentNewsSection"
import AwardsSection from "../components/Home/AwardsSection"
import BestTeachingSection from "../components/Home/BestTeachingSection"
// import HistorySection from "../components/Home/HistorySection"
import RegisterSection from "../components/RegisterSection"
import OurGuidesSection from "../components/Home/OurGuidesSection"

export const query = graphql`
  query {
    girlAvatar: file(relativePath: { eq: "GirlAvatar.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boyAvatar: file(relativePath: { eq: "BoyAvatar.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default function Home({ data }) {
  return (
    <Box>
      <SEO title="Home" />
      <Layout isIndexPage={true}>
        <BannerWithSlider />
        <BestTeachingSection />
        <AchievementsSection />
        {/* <HistorySection /> */}

        <OurGuidesSection />
        <TestimonialSection
          images={[
            data.girlAvatar.childImageSharp.fixed,
            data.boyAvatar.childImageSharp.fixed,
          ]}
        />
        {/* <ResultsSection
          images={[
            data.boyAvatar.childImageSharp.fixed,
            data.girlAvatar.childImageSharp.fixed,
          ]}
        /> */}
        <AwardsSection />
        <RecentNewsSection />
        {/* <MapSection /> */}
        <RegisterSection />
        <PostCardSection />
      </Layout>
    </Box>
  )
}
