import {
  ModalOverlay,
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Image,
  Stack,
  Heading,
  Box,
  Text,
} from "@chakra-ui/core"
import Logo from "../../images/shortLogo.png"
import React from "react"
import Button from "../components/Button"

const RegisterModal = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      size={["xs", "sm", "md"]}
    >
      <ModalOverlay />
      <ModalContent
        boxShadow="0 6px 10px rgba(0,0,0,.08)"
        p={5}
        borderRadius={35}
      >
        <ModalHeader p={0}>
          {" "}
          <Image mx="auto" src={Logo} alt="logo" h="100px" />
        </ModalHeader>
        <ModalCloseButton
          mr={3}
          mt={3}
          size="lg"
          _hover={{ bg: "none", color: "#2E3292" }}
          _focus={{ boxShadow: "none" }}
          _active={{ bg: "none" }}
        />
        <ModalBody pt={0}>
          <Heading size="xl" color="#2E3292" textAlign="center">
            பதிவு செய்க
          </Heading>
          <Heading fontSize="md" textAlign="center" mt={2} px={1} mb={12}>
            எங்கள் நிபுணர்கள் சிறிது நேரத்தில் உங்களை அழைப்பார்கள்
          </Heading>
          <form
            id="requestCall"
            name="requestCall"
            method="post"
            data-netlify="true"
            action="/ThankYou/requestCall"
          >
            <Stack spacing={6} my={3}>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="மின்னஞ்சல் முகவரி"
                fontSize="md"
                isRequired
              />
              <Box>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="கைபேசி எண்"
                  fontSize="md"
                  isRequired
                />
                <Box p={1}>
                  <Text fontSize="xs">
                    * உங்கள் எண் சந்தைப்படுத்தல் பயன்படுத்தப்படாது
                  </Text>
                </Box>
              </Box>
              <Button
                bg="#0CBF5F"
                mt={5}
                px={6}
                py={2}
                mx="auto"
                color="white"
                borderRadius="4em"
                fontSize="xl"
                type="submit"
                _hover={{ bg: "none" }}
              >
                அழைப்பு கோரிக்கை
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RegisterModal
