import React, { useState } from "react"
import { Box, Grid, Heading } from "@chakra-ui/core"
import { ArrowRightShort } from "@emotion-icons/bootstrap/ArrowRightShort"
import VideoSlider from "../VideoSlider"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../Button"
import GatsbyImage from "gatsby-image"
import { Fade } from "react-awesome-reveal"
import TagLineCarousel from "./TagLineCarousel"
import RegisterModal from "../RegisterModal"
import FloatingLetters from "../FloatingLetters"
import NewAnnouncements from "./NewAnnouncements"

const BannerWithSlider = () => {
  const [modal, setModal] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "templeBg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bigLogo: file(relativePath: { eq: "AatchiTamizhLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box
      height={["850px", "1100px", null, "850px"]}
      position="relative"
      zIndex={1}
    >
      <FloatingLetters />
      <RegisterModal isOpen={modal} onClose={() => setModal(false)} />
      <Box textAlign="center" mx="auto" maxW="1200px" py={10} pt={[5, 10, 48]}>
        <Fade duration={500} triggerOnce>
          <Box mx="auto" maxW={["280px", null, "500px"]}>
            <GatsbyImage fluid={data.bigLogo.childImageSharp.fluid} />
          </Box>
        </Fade>
        <Fade direction="up" delay={500} duration={500} triggerOnce>
          <Box mt={3}>
            <TagLineCarousel />
          </Box>
        </Fade>
        <Box px={[3, 4, 8]} mt={8}>
          <Fade triggerOnce={true} duration={500} direction="left">
            <Button
              bg="#FFD400E8"
              color="#000"
              borderRadius="3em"
              fontSize="xl"
              px={8}
              py={6}
              onClick={() => setModal(true)}
            >
              பதிவு செய்க
              <Box ml={1}>
                <ArrowRightShort size="2rem" />
              </Box>
            </Button>
          </Fade>
        </Box>
      </Box>
      <Box mt={[-8, null, 0]}>
        <Grid
          templateColumns={["1fr", null, null, "2fr 1fr"]}
          p={1}
          gap={5}
          w="100%"
        >
          <Box>
            <Fade triggerOnce duration={500} direction="left">
              <Heading
                boxShadow="0 1px 4px rgba(26,26,67,0.5)"
                bg="#2E3292"
                color="#FFFF"
                p={3}
                size="md"
                fontWeight="medium"
              >
                புதிய Videos
              </Heading>
              <VideoSlider />
            </Fade>
          </Box>{" "}
          <Fade triggerOnce duration={500} direction="right">
            <NewAnnouncements />
          </Fade>
        </Grid>
      </Box>
    </Box>
  )
}
export default BannerWithSlider
