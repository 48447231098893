import React from "react"
import trophyIcon from "../../../images/trophyIcon.svg"
import scholarIcon from "../../../images/scholarIcon.svg"
import notesIcon from "../../../images/notes.svg"
import testPadIcon from "../../../images/testPadIcon.svg"
import CountUp from "react-countup"
import { Flex, Box, Image, Text, Stack } from "@chakra-ui/core"
import { Fade } from "react-awesome-reveal"
import { Waypoint } from "react-waypoint"

interface AchievmentsCardProps {
  image: string
  title: number
  subTitle: string
}

const AchievmentsSectionCard = (props: AchievmentsCardProps) => {
  return (
    <Stack
      isInline
      spacing={3}
      w={["180px", null, "280px"]}
      py={2}
      align="center"
    >
      <Image src={props.image} height={["60px", "90px", "100px"]} />
      <Box>
        <Text
          fontFamily="Helvetica Neue"
          fontSize={["2xl", null, "5xl"]}
          lineHeight={1.2}
          fontWeight="bold"
        >
          <CountUp end={props.title} duration={2}>
            {({ countUpRef, start }) => (
              <Flex align="center">
                <span ref={countUpRef} />
                <span>+</span>
                <Waypoint onEnter={start} />
              </Flex>
            )}
          </CountUp>
        </Text>
        <Text fontSize={["sm", null, "xl"]} color="#707070">
          {props.subTitle}
        </Text>
      </Box>
    </Stack>
  )
}
const AchievmentsSection = () => {
  return (
    <Fade>
      <Flex
        justify="space-evenly"
        wrap="wrap"
        bg="#FFEFE086"
        py={[5, null, 10]}
        mt={3}
      >
        <AchievmentsSectionCard
          image={scholarIcon}
          title={100000}
          subTitle="மாணவர்கள்"
        />
        <AchievmentsSectionCard
          image={notesIcon}
          title={14}
          subTitle="வருடம்"
        />
        <AchievmentsSectionCard
          image={trophyIcon}
          title={23000}
          subTitle="தேர்ச்சிகள்"
        />
        <AchievmentsSectionCard
          image={testPadIcon}
          title={5000}
          subTitle="பயிற்சி தேர்வுகள்"
        />
      </Flex>
    </Fade>
  )
}
export default AchievmentsSection
