import React from "react"
import { PseudoBox } from "@chakra-ui/core"
import { Link } from "gatsby"

interface BlogLinkProps {
  to: string
  children: React.ReactNode
}

const BlogLink = (props: BlogLinkProps): JSX.Element => {
  const { to, children } = props
  return (
    <Link to={to}>
      <PseudoBox
        as="span"
        display="inline-block"
        textDecoration="none"
        color="#212121"
        _hover={{ textDecoration: "underline" }}
      >
        {children}
      </PseudoBox>
    </Link>
  )
}

export default BlogLink
