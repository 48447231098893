import React from "react"
import { Box, Heading, Text, PseudoBox, Divider } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

const slide = keyframes`
from {
  transform: translateY(50%);
 }
to {
  transform: translateY(-100%);
 }
 
`
const AnimatedContainer = styled(PseudoBox)`
  &:hover {
    animation-play-state: paused;
  }
`
const newAnnouncementsTexts = [
  "தமிழக போலீஸ் தேர்வுக்கான தேதி அறிவிக்கப்பட்டுள்ளது",
  "இலவச ஓன்லைன் கருத்தரங்கம் திரு நடராஜன் அவர்கள் தலைமையில் Oct 20 நடைபெற உள்ளது தமிழக போலீஸ் தேர்வுக்கான தேதி அறிவிக்கப்பட்டுள்ளது",
  "தமிழக போலீஸ் தேர்வுக்கான தேதி அறிவிக்கப்பட்டுள்ளது",
  "இலவச ஓன்லைன் கருத்தரங்கம் திரு நடராஜன் அவர்கள் தலைமையில் Oct 20  நடைபெற உள்ளது தமிழக போலீஸ் தேர்வுக்கான தேதி அறிவிக்கப்பட்டுள்ளது",
]
const NewAnnouncements = () => {
  return (
    <Box w="100%">
      <Heading
        bg="#2E3292"
        color="#FFFF"
        p={3}
        size="md"
        fontWeight="medium"
        boxShadow="0 1px 4px rgba(26,26,67,0.5)"
      >
        புதிய அறிவிப்புகள்
      </Heading>
      <Box
        h="200px"
        mt={5}
        borderColor="#dadada"
        borderWidth="1px"
        overflow="hidden"
        fontWeight="medium"
        cursor="pointer"
      >
        <AnimatedContainer animation={`${slide} 20s infinite linear}`}>
          {newAnnouncementsTexts.map(text => (
            <>
              <Text p={2}>{text}</Text>
              <Divider
                mt={1}
                opacity={1}
                w="100%"
                borderColor="#dadada"
                borderWidth="1px"
                alignSelf="center"
              />
            </>
          ))}
        </AnimatedContainer>
      </Box>
    </Box>
  )
}
export default NewAnnouncements
