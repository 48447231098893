import React from "react"
import {
  Avatar,
  Box,
  Grid,
  Text,
  Heading,
  SimpleGrid,
  Image,
  Flex,
  PseudoBox,
} from "@chakra-ui/core"
import { keyframes } from "@emotion/core"
import starImg from "../../images/star-fill.svg"
import { Fade } from "react-awesome-reveal"
import styled from "@emotion/styled"

const slide = keyframes`
from {
  transform: translateY(0%);
 }
to {
  transform: translateY(-50%);
 }
 
`

const AnimatedContainer = styled(PseudoBox)`
  &:hover {
    animation-play-state: paused;
  }
`

interface TestimonialData {
  name: string
  testimonial: string
}

const leftData: TestimonialData[] = [
  {
    name: "Vishal Vishal",
    testimonial:
      "My sister Usha Rani got a government job from this Academy. and also my friends got a government job in group 4 service's. Thanks Mr.Natraj sir and veerababu sir...",
  },
  {
    name: "Indhu Bala",
    testimonial:
      "Wide range of books for references, excellent way of teaching, More no.of question paper for solving, on the whole good place to crack the exam in the 1st attempt",
  },
  {
    name: "Divyadharshini B",
    testimonial:
      "Best academy in chennai. I have cleared Group 1 exam both Prelims and mains with the guidance of aatchi thamizh IAS Academy.  Thank you aatchi thamizh💪",
  },
  {
    name: "Natarajan k",
    testimonial:
      "police department examkku ponen one attempt la clear in exam Very nice academy  now working greater Chennai police thanks veerabapu sir and former DGP Nataraj sir",
  },
]

const rightData: TestimonialData[] = [
  {
    name: "Mahesh Kannan",
    testimonial:
      "Aatchi Tamil is good coaching center for all  government exam like tnbsc exams  and  Ias exam, ssc exam to study. The vetri kalanjitam material is very nice in tamil and English version.it is very useful to my studies. ",
  },
  {
    name: "Naveen Naveen",
    testimonial:
      "Friendly approach of faculties, New syllabus covered, Good Mentorship, well guided are things to crack easily. You get all these things from Aatchithamizh academy.",
  },
  {
    name: "Varun Mathew",
    testimonial:
      "You can access Books, teachings from any part of the world but the motivation of cracking the examination lies within you, here in Aatchithamizh they will ignite the fire in you to crack the examination by yourself",
  },
  {
    name: "Aadhithya Arunachalam",
    testimonial:
      "Well trained instructors, spacious classes, useful materials, with positive surroundings. Good place to learn.",
  },
]

const TestimonialCard = ({ name, testimonial, image }): JSX.Element => {
  return (
    <Box
      mb={3}
      p={[2, null, 3]}
      maxW="350px"
      bg="#fff"
      borderRadius="1em"
      cursor="pointer"
    >
      <Box p={1}>
        <Text fontSize={["sm", "md"]} textAlign="center" color="#707070" mt={3}>
          <Text as="span" fontSize={["md", null, "lg"]}>
            “
          </Text>
          &nbsp;{testimonial}&nbsp;
          <Text as="span" fontSize={["md", null, "lg"]}>
            ”
          </Text>
        </Text>
        <Grid
          mt={3}
          mb={3}
          ml={1}
          templateColumns={"auto 1fr"}
          gap={[1, null, 2]}
          alignItems="center"
        >
          <Avatar name={name} />
          <Box>
            <Heading fontSize={["md", null, "lg"]}>{name}</Heading>
            <Flex mt={1}>
              <Image maxW="1rem" src={starImg} />
              <Image maxW="1rem" src={starImg} />
              <Image maxW="1rem" src={starImg} />
              <Image maxW="1rem" src={starImg} />
              <Image maxW="1rem" src={starImg} />
            </Flex>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const TestimonialSection2 = ({ images }): JSX.Element => {
  return (
    <Box bg="#f5f5f5" mt={7} position="relative" zIndex={0}>
      <SimpleGrid
        px={[5, null, 10]}
        pr={[0, null, 10]}
        columns={[1, null, 2]}
        h={["900px", null, "600px"]}
        overflow="hidden"
        position="relative"
        boxShadow="0 10px 10px -11px rgba(0, 0, 0, 0.35) inset, 0 -10px 10px -11px rgba(0, 0, 0, 0.35) inset"
      >
        <Box py={16}>
          <Fade triggerOnce duration={500} direction="up" delay={300}>
            <Heading fontSize={["3xl", null, "4xl"]}>
              உங்களின் ஒவ்வொரு முயற்சியிலும் உறுதுணையாக இருக்கிறோம்.
            </Heading>
          </Fade>
          <Fade triggerOnce duration={500} direction="right" delay={900}>
            <Text color="#707070" mt={2} maxW="600px">
              கற்றலின் ஆகாசத்தில் நீங்கள் தனித்தன்மை வாய்ந்த ஒரு பறவையாகிறீர்கள்
              என்பதே எங்கள் பெரும்மகிழ்ச்சி. திறமையான போட்டியாளர்களே
              வெற்றியாளர்களாக உருவாகிறார்கள் என்பதே எங்களின் தாரகமந்திரம்.
              தொடர்ந்து உங்களுக்காக உழைக்கிறோம் உங்களோடு உழைக்கிறோம்
            </Text>
          </Fade>
        </Box>
        <SimpleGrid
          overflow="hidden"
          position="relative"
          columns={2}
          spacing={3}
        >
          <AnimatedContainer animation={`${slide} 30s infinite linear}`}>
            {leftData.map((item, index) => (
              <TestimonialCard
                key={index}
                image={images[index % 2]}
                name={item.name}
                testimonial={item.testimonial}
              />
            ))}
            {leftData.map((item, index) => (
              <TestimonialCard
                key={index}
                image={images[index % 2]}
                name={item.name}
                testimonial={item.testimonial}
              />
            ))}
          </AnimatedContainer>
          <AnimatedContainer animation={`${slide} 13s infinite linear`}>
            {rightData.map((item, index) => (
              <TestimonialCard
                key={index}
                image={images[index % 2]}
                name={item.name}
                testimonial={item.testimonial}
              />
            ))}
            {rightData.map((item, index) => (
              <TestimonialCard
                key={index}
                image={images[index % 2]}
                name={item.name}
                testimonial={item.testimonial}
              />
            ))}
          </AnimatedContainer>
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  )
}

export default TestimonialSection2
