import React from "react"
import { Box, Image } from "@chakra-ui/core"
import அImg from "../../images/அ.svg"
import கImg from "../../images/க.svg"
import ண்Img from "../../images/ண்.svg"
import தImg from "../../images/த.svg"
import ழ்Img from "../../images/ழ்.svg"
import { keyframes } from "@emotion/core"

const upDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(30px);
  }
`

const upDownWide = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200px);
  }
`

const FloatingLetters = (): JSX.Element => {
  return (
    <Box>
      <Image
        src={அImg}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top={"10%"}
        left="5%"
        zIndex={-1}
        w={["100px", null, "150px", "250px"]}
      />
      <Image
        src={அImg}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        bottom={"10%"}
        left="5%"
        zIndex={-1}
        display={["none", null, "block"]}
        w={["100px", null, "150px", "250px"]}
      />
      <Image
        src={கImg}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top={["8%", "15%"]}
        right="15%"
        zIndex={-1}
        w={["80px", null, "100px", "200px"]}
      />
      <Image
        src={ண்Img}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top="40%"
        left={"5%"}
        zIndex={-1}
        w={["80px", null, "100px", "200px"]}
      />
      <Image
        src={ண்Img}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top="35%"
        right={0}
        zIndex={-1}
        w={["80px", null, "100px", "200px"]}
      />
      <Image
        src={தImg}
        animation={`${upDownWide} 18s ease-in-out infinite alternate`}
        position="absolute"
        bottom={"15%"}
        left={"30%"}
        zIndex={-1}
        w={["80px", null, "100px", "200px"]}
      />
      <Image
        src={தImg}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top={"20%"}
        left={"20%"}
        zIndex={-1}
        w={["80px", null, "100px", "200px"]}
      />
      <Image
        src={ழ்Img}
        animation={`${upDown} 4s ease-in-out infinite alternate`}
        position="absolute"
        top={["20%"]}
        left={["50%"]}
        zIndex={-1}
        w={["60px", null, "80px", "180px"]}
      />
    </Box>
  )
}

export default FloatingLetters
