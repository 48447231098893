import React from "react"
import Slider from "react-slick"
import { Text } from "@chakra-ui/core"

const settings = {
  infinite: true,
  autoplay: true,
  arrows: false,
  dots: false,
  autoplaySpeed: 3000,
  vertical: true,
  verticalSwiping: true,
}

const tagLineList: string[] = [
  "தலைநகரில் தனித்துவம்; தலைநிமிரட்டும் தமிழகம்!",
  "ஆட்சித்தமிழில் சேருங்கள்; அரசுப்பணியாளராக மாறுங்கள்!",
  "எல்லாத்தேர்வுகளையும் வெல்ல வைக்கும் ஒற்றைப் பயிற்சி மையம்!",
  "தமிழால் முடியும்; ஆட்சித்தமிழால் விடியும்!",
  "ஐ.ஏ.எஸ்-தமிழால் வெல்ல முடியும்!",
  "முற்றிலும் தமிழில் பயிற்சி; நிச்சய வெற்றிக்கான முழுமையான  முயற்சி!",
]

const TagLineCarousel = (): JSX.Element => {
  return (
    <Slider {...settings}>
      {tagLineList.map((tagLine, index) => (
        <Text key={index} fontSize="xl" fontWeight="600">
          {tagLine}
        </Text>
      ))}
    </Slider>
  )
}

export default TagLineCarousel
