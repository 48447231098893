import { Box, Text, Heading, Link, Stack, Collapse } from "@chakra-ui/core"
import Img from "gatsby-image"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from "../SectionTitle"
import { Slide } from "react-awesome-reveal"

const OurGuidesSection = () => {
  const [show, setShow] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      natrajIps: file(relativePath: { eq: "natraj.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box my={12} py={12}>
      <Box>
        <SectionTitle title="நமது வழிகாட்டி" />
      </Box>
      <Stack spacing={12} my={10}>
        <Box w="90vw" maxW="500px" mx="auto" my={12}>
          <Slide direction="left" triggerOnce duration={500}>
            <Box boxShadow="0 6px 10px rgba(0,0,0,.08)">
              <Img
                fluid={data.natrajIps.childImageSharp.fluid}
                alt="Nataraj IPS"
              />
            </Box>
          </Slide>
        </Box>
        <Slide direction="right" triggerOnce duration={500}>
          <Box maxW="700px" mx="auto">
            <Heading mb={5} size="xl" textAlign={"center"}>
              திரு.இரா.நட்ராஜ் இந்தியக் காவல் பணி
            </Heading>
            <Box textAlign="center" fontSize="lg" mb={5} px={[2, 0]}>
              <Text mb={1}>
                மேனாள் தலைவர், தமிழ்நாடு அரசுப் பணியாளர் தேர்வாணையம்
                {/* <Link
                  mx={1}
                  display={show ? "none" : "inline-block"}
                  color="blue.500"
                  onClick={() => setShow(!show)}
                >
                  மேலும் வாசிக்க...
                </Link> */}
              </Text>
                <Text>
                தமிழ்நாடு காவல்துறை மேனாள் தலைவர்
                </Text>
              {/* <Collapse isOpen={show}>
                <Text mb={4}>
                  துறைசார் வல்லுநர்களோடு கலந்து கொண்டு பாடத்திட்டங்களை
                  முறைப்படுத்துவதிலும், கலந்துரையாடல்கள் வழி மாணவர்களுக்கு
                  பயிற்சியளிப்பதிலும் காட்டும் முனைப்பென்பது ஆட்சித்தமிழுக்கும்
                  மாணவர்களுக்கும் கிடைத்த பெருஞ்செயலென்றே கருதுகிறோம்.
                </Text>
                <Text mb={4}>
                  பின்தங்கிய மாணவர்களும் மற்றும் அரசுப் பள்ளி மாணவர்களும் ஆட்சி
                  அதிகாரத்தில் தாய்மொழி வழியே பயின்ற மாணவர்கள் பங்குபெற வேண்டும்
                  என்னும் முனைப்போடு தொழில்நுட்ப வளர்ச்சி மிகுந்த
                  இக்காலகட்டத்தில் தொலைநோக்குப் பார்வையில் பாடத் தொகுப்புகளைக்
                  கட்டமைப்பதிலும், வகுப்புகளை சீர்செய்வதிலும், தேர்வுக்குத்
                  தயாராகும் போட்டியாளர்களுக்கு கற்பிப்பதிலும் காட்டப்படவேண்டிய
                  முனைப்பு குறித்து ஆய்வதிலும் அய்யாவின் பங்கு அளப்பரியது. அதுவே
                  போட்டித் தேர்வெழுதும் மாணவர்களின் ஒவ்வொரு முனைப்பிலும் எங்களை
                  மேலும் மேலும் உந்துசக்தியாக இயக்குகிறது.{" "}
                  <Link color="blue.500" mx={1} onClick={() => setShow(!show)}>
                    சுருகுக
                  </Link>
                </Text>
              </Collapse> */}
            </Box>
          </Box>
        </Slide>
      </Stack>
    </Box>
  )
}

export default OurGuidesSection
