const getPosts = data => {
  const getCategories = edge => {
    if (
      edge.node.data.categories.length === 0 ||
      edge.node.data.categories.some(
        category => category.category.document === null
      )
    ) {
      return []
    }
    return edge.node.data.categories.map(
      category => category.category.document.data
    )
  }

  return data.allPrismicPost.edges.map(edge => ({
    title: edge.node.data.title.text,
    description: edge.node.data.description.text,
    date: edge.node.data.date,
    image: {
      url: edge.node.data.main_image.url,
      alt: edge.node.data.main_image.alt,
    },
    categories: getCategories(edge),
    url: edge.node.url,
    content: edge.node.data.content.raw,
  }))
}

const getPostLink = post => {
  return `/blog${post.url}`
}

module.exports = {
  getPosts,
  getPostLink,
}
