import React, { useState } from "react"
import { Box, Image, Stack } from "@chakra-ui/core"
import SectionTitle from "../SectionTitle"
import Award1 from "../../../images/Award1.png"
import Award2 from "../../../images/Award2.png"
import Award3 from "../../../images/Award3.png"
import "./slider.css"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Zoom } from "react-awesome-reveal"

const images = [
  {
    original: Award1,
  },
  {
    original: Award2,
  },
  {
    original: Award3,
  },

  {
    original: Award1,
  },
  {
    original: Award2,
  },
  {
    original: Award3,
  },
]

const getStyles = (index, activeIndex, length) => {
  if (activeIndex + 1 === length ? index === 0 : index === activeIndex + 1) {
    return {
      transform: "scale(1)",
      opacity: [1],
    }
  } else {
    return {
      transform: ["scale(1)", null, null, "scale(0.7)"],
      opacity: [1, null, null, 0.8],
    }
  }
}

const AwardsSection = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 3000,
    horizontal: true,
    slidesToShow: 3,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "Awardsbg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage Tag="section" fluid={data.bgImage.childImageSharp.fluid}>
      <Stack spacing={10} py={10}>
        <Box my={10}>
          <SectionTitle title="விருதுகள்" />
        </Box>
        <Box
          minH="400px"
          py={10}
          mt={10}
          maxW={["300px", "600px", null, "900px"]}
          mx="auto"
        >
          <Zoom duration={500} triggerOnce>
            <Slider {...settings}>
              {images.map((image, index) => (
                <Box
                  {...getStyles(index, activeIndex, 6)}
                  transition="all 0.3s"
                >
                  <Image
                    mx="auto"
                    w={["300px", "400px"]}
                    h={["220px", "250px"]}
                    src={image.original}
                  />
                </Box>
              ))}
            </Slider>
          </Zoom>
        </Box>
      </Stack>
    </BackgroundImage>
  )
}
export default AwardsSection
